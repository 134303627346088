import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import AtlasMap from './components/AtlasMap' 
import SearchPanel from './components/SearchPanel'

import GatDataService from './service/GatDataService'

import './TrachomaAtlas.scss'

const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
})

const defaultMapConfig = {
  viewport: {
    center: [10.6111, 0],
    zoom: 3
  }
}

const defaultSearch = {
  "adminIDs" : {
    "admin0": [],
    "admin1": [],
    "admin2": [],
    "admin3": []
  },
  "year": new Date().getFullYear(),
  "yearTreatment": new Date().getFullYear(),
  "layers": {
    "prevalence": true,
    "treatment": false,
    "baselineTFPrevalence": false,
    "treatmentCumulative": false,
    "reduction50": false,
    "endemicStatusCountry": false,
    "prevalenceTrichiasis": false,
    "ius": false,
    "admin1": false,
    "admin1_names": false
  }
}

export default class TrachomaAtlas extends React.Component {

  constructor(props) {
    super(props)
    this.dataService = new GatDataService({url: props.gatDataUrl})
    this.state = {
      reloadIndex: 0,
      search: defaultSearch,
      zoom: defaultMapConfig.viewport.zoom,
      mapConfig: {
        ...defaultMapConfig,
        ...props.mapConfig
      }
    }
    this.searchChanged = this.searchChanged.bind(this)
    this.printMap = this.printMap.bind(this)
    this.printPDF = this.printPDF.bind(this)
    this.resetMap = this.resetMap.bind(this)
  }

  resetMap() {
    this.setState({
      reloadIndex: this.state.reloadIndex+1,
      search: defaultSearch,
      zoom: defaultMapConfig.viewport.zoom,
      mapConfig: {
        ...defaultMapConfig,
        ...this.props.mapConfig
      }
    })
    // this.forceUpdate();
  }

  printMap() {
    this.atlasMap.print()
  }

  printPDF() {
    this.atlasMap.printPDF()
  }

  searchChanged(search) {
    this.setState({
      search: {...search, adminIDs: search.adminIDs}
    })
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="TrachomaAtlas" key={this.state.reloadIndex}>
          <AtlasMap ref={instance => { this.atlasMap = instance }} 
            {...this.props}
            search={this.state.search}
            mapConfig={this.state.mapConfig}
            zoomChanged={zoom => this.setState({zoom: zoom})}
            layers={this.state.search.layers}
            year={this.state.search.year}
            yearTreatment={this.state.search.yearTreatment}
          />
          <SearchPanel 
            zoom={this.state.zoom}
            search={this.state.search} 
            searchChanged={this.searchChanged}
            dataService={this.dataService}
            printMap={this.printMap}
            printPDF={this.printPDF}
            resetMap={this.resetMap}
          />
        </div>
      </ThemeProvider>
    )
  }

}
