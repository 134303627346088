import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TrachomaAtlas from './TrachomaAtlas';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <TrachomaAtlas 
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN} 
      tileLayerUrl={process.env.REACT_APP_TILE_LAYER_URL}
      labelLayerUrl={process.env.REACT_APP_LABEL_LAYER_URL}
      gatDataUrl={process.env.REACT_APP_GAT_DATA_URL}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
