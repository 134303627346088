import React from 'react'

import { legendTitles, legendStyles } from '../service/Definitions'
import './Legend.scss'

import Locale from '../locales'

const l = new Locale()

export default class Legend extends React.Component {

  render() {
    let layerStyles = []
    for (var layer in this.props.layers) {
      if (this.props.layers[layer]) {
        let styles = []
        if (layer === 'treatment') {
          styles = this.props.layers.prevalence ? 
            styles.concat(legendStyles.treatment) :
            styles.concat(legendStyles.treatmentNoPrevalence)
        } else if (layer === 'prevalenceTrichiasis') { 
          styles = this.props.layers.prevalence ? 
            styles.concat(legendStyles.prevalenceTrichiasis) :
            styles.concat(legendStyles.prevalenceTrichiasisNoTF)
        } else if (legendStyles[layer]) {
          styles = styles.concat(legendStyles[layer])
        }
        if (styles.length > 0) {
          layerStyles = layerStyles.concat({
            title: legendTitles[layer],
            styles: styles
          })
        }
      }
    }
    return (
      <div className="legend" {...this.props}>
        <h4>Legend</h4>
        {layerStyles.map((layerStyle) => {
          if (layerStyle && layerStyle.styles.length > 0) {
            return (
              <React.Fragment key={layerStyle.title}>
                <h5>{l.t(layerStyle.title)}</h5>
                <ul>
                  {layerStyle.styles.map((value) => {
                    return (
                      <li key={value.text}>
                        <span className="legendIcon" style={value.style}></span>
                        <span className="legendText">{l.t(value.text)}</span>
                      </li>
                    )
                  })}
                </ul>
              </React.Fragment>
            )
          } else {
            return <></>
          }
        })}
      </div>
    )
  }

}