export default {
  prevalenceSuspected: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-pattern': [
        "match", 
        ["get", "survey_tf_category"], 
        'Suspected Endemic', 'Suspected Endemic',
        'default'
      ],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  prevalence: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': [
        "match", 
        ["get", "survey_tf_category"], 
        '<5%', "#8CED36", 
        'Evidence (other than conventional statistical analysis of TF data) indicates that active trachoma is not a public health problem', "#8CED36", 
        '5-9.9%', "#F7FF00", 
        '10-29.9%', "#FFCC00", 
        '30-49.9%', "#F9865F", 
        '>=50%', "#F70804", 
        "rgba(0,0,0,0)"
      ], 
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  treatment: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-pattern': [
        "match", 
        ["get", "zx_treatments"], 
        1, "DiagonalBlack180", 
        "default"
      ],  
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  baselineTFPrevalence: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': [
        "match", 
        ["get", "baseline_tf_category_string"], 
        '< 5', "#8CED36", 
        '5-9.9', "#F7FF00", 
        '10-29.9', "#FFCC00", 
        '30-49.9', "#F9865F", 
        '>= 50', "#F70804", 
        "rgba(0,0,0,0)"
      ], 
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  treatmentNoPrevalence: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': [
        "match", 
        ["get", "zx_treatments"], 
        1, "#7B00B4", 
        "rgba(0,0,0,0)"
      ],  
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(255,255,255,1)"
    }
  },
  treatmentCumulative: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': [
        "match", 
        ["get", "zx_rounds_cumulative"], 
        0, 'rgba(0,0,0,0)',
        1, '#F9BBF9',
        2, '#DD00FF',
        3, '#7B00B4',
        4, '#3A007E',
        '#11002F'
      ],  
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.8
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  reduction50: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': 'rgb(0,255,196)',  
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.8
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  endemicStatusCountry: {
    'type': 'fill',
    'source': 'gatcountries',
    'source-layer': 'gatcountries',
    'layout': {},
    'paint': {
      'fill-color': [
        'match',
        ['get', 'endemicity_status'],
        'Thought to not require interventions, claims to have eliminated', '#0015FF',
        'Thought to not require interventions', '#696969',
        'Validated as having eliminated', '#1F9C00',
        'Known to require interventions', '#D6301D',
        'May require interventions, investigation needed', '#FF9900',
        'rgba(0,0,0,0)'
      ],  
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.8
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  prevalenceTrichiasis: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-pattern': [
        "match", 
        ["get", "tt_category_string"], 
        '>=0.2%', "DiagonalBlack",  
        "rgba(0,0,0,0)"
      ], 
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  prevalenceTrichiasisNoTF: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-color': [
        "match", 
        ["get", "tt_category_string"], 
        '<0.2%', "#8CED36",
        'Evidence (other than conventional statistical analysis of TT data) indicates that TT is not a public health problem', "#8CED36",
        '>=0.2%', "#F7FF14",  
        "rgba(0,0,0,0)"
      ], 
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  prevalenceTrichiasisNoTFSuspectedEndemic: {
    'type': 'fill',
    'source': 'gat',
    'source-layer': 'gat',
    'layout': {},
    'paint': {
      'fill-pattern': [
        "match", 
        ["get", "tt_category_string"], 
        'Suspected Endemic', "Suspected Endemic", 
        "default"
      ], 
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0.6
      ],
      'fill-outline-color': "rgba(120,120,120,0.6)"
    }
  },
  ius: {
    'type': 'fill',
    'source': 'ius',
    'source-layer': 'ius',
    'paint': {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        'rgba(0,0,0,0.03)',
        'rgba(255,255,255,0)'
      ]
    }
  },
  iusBorder: {
    'type': 'line',
    'source': 'ius',
    'source-layer': 'ius',
    'paint': {
      'line-color': 'rgba(120,120,120,0.8)',
      'line-width': 0.5
    }
  },
  admin1: {
    'type': 'line',
    'source': 'admin1',
    'source-layer': 'admin1',
    'paint': {
      'line-color': 'rgba(100,100,100,0.8)',
      'line-width': 1
    }
  },
  admin1_names: {
    'type': 'symbol',
    'source': 'admin1',
    'source-layer': 'admin1_names',
    'layout': {
      'symbol-placement': 'point',
      'text-field': ['get', 'admin1'],
      'text-justify': 'auto',
      'text-anchor': 'center',
      'text-size': 11,
      'text-allow-overlap': false,
      'text-variable-anchor': ["center", "left", "right", "top", "bottom", "top-left", "top-right", "bottom-left", "bottom-right"]
    },
    'paint': {
      "text-color": "#202",
      "text-halo-color": "#fff",
      "text-halo-width": 2
    }
  }
}