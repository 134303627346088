import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, IconButton, Button, ButtonGroup, Divider, Checkbox, Typography } from '@material-ui/core'
import { SkipNext, SkipPrevious, Help, Print, Image, Lock, LockOpen, Info, Autorenew, PictureAsPdf } from '@material-ui/icons'
import { debounce, trim } from 'lodash'

import SearchToggle from './form/SearchToggle'
import Legend from './Legend'
import InfoPopover from './InfoPopover'
import './SearchPanel.scss'
import Locale from '../locales'

const SEARCH_TEXT_MIN_LENGTH = 3
const MIN_YEAR = 2010

const l = new Locale();


function expandBounds(oldBounds, bounds) {
  let newBounds = [[], [], [], []]
  if (oldBounds) {
    if (bounds[0][0] < oldBounds[0][0]) {
      newBounds[0][0] = bounds[0][0]
    } else {
      newBounds[0][0] = oldBounds[0][0]
    }
    if (bounds[0][1] < oldBounds[0][1]) {
      newBounds[0][1] = bounds[0][1]
    } else {
      newBounds[0][1] = oldBounds[0][1]
    }
    if (bounds[1][0] < oldBounds[1][0]) {
      newBounds[1][0] = bounds[1][0]
    } else {
      newBounds[1][0] = oldBounds[1][0]
    }
    if (bounds[1][1] > oldBounds[1][1]) {
      newBounds[1][1] = bounds[1][1]
    } else {
      newBounds[1][1] = oldBounds[1][1]
    }
    if (bounds[2][0] > oldBounds[2][0]) {
      newBounds[2][0] = bounds[2][0]
    } else {
      newBounds[2][0] = oldBounds[2][0]
    }
    if (bounds[2][1] > oldBounds[2][1]) {
      newBounds[2][1] = bounds[2][1]
    } else {
      newBounds[2][1] = oldBounds[2][1]
    }
    if (bounds[3][0] > oldBounds[3][0]) {
      newBounds[3][0] = bounds[3][0]
    } else {
      newBounds[3][0] = oldBounds[3][0]
    }
    if (bounds[3][1] < oldBounds[3][1]) {
      newBounds[3][1] = bounds[3][1]
    } else {
      newBounds[3][1] = oldBounds[3][1]
    }
    return newBounds
  } else {
    return bounds
  }
}

export default class SearchPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ...props.search,
      searchAdmins: [],
      loadingSearchAdmins: false,
      lockYears: true,
      maxYear: new Date().getFullYear(),
      minYear: MIN_YEAR,
      disclaimers: false,
      disclaimersAnchor: null
    }
    this.binds()
  }

  binds() {
    this.adminSearchChanged = this.adminSearchChanged.bind(this)
    this.yearChanged = this.yearChanged.bind(this)
    this.yearPlus = this.yearPlus.bind(this)
    this.yearMinus = this.yearMinus.bind(this)
    this.yearTreatmentChanged = this.yearTreatmentChanged.bind(this)
    this.yearTreatmentPlus = this.yearTreatmentPlus.bind(this)
    this.yearTreatmentMinus = this.yearTreatmentMinus.bind(this)
    this.toggleChanged = this.toggleChanged.bind(this)
    this.adminsChanged = this.adminsChanged.bind(this)
    this.lockYearsChanged = this.lockYearsChanged.bind(this)
    this.openDisclaimers = this.openDisclaimers.bind(this)
    this.closeDisclaimers = this.closeDisclaimers.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.adminIDs !== this.state.adminIDs ||
      prevState.adminBounds !== this.state.adminBounds || 
      prevState.year !== this.state.year ||
      prevState.yearTreatment !== this.state.yearTreatment ||
      prevState.layers !== this.state.layers
    ) {
      this.props.searchChanged(this.state)
    }
  }

  adminSearchChanged = debounce(async (text) => {
    this.setState({loadingSearchAdmins: true})
    const searchText = trim(text)
    let admins = []
    if (searchText.length >= SEARCH_TEXT_MIN_LENGTH) {
      admins = await this.props.dataService.getAdmins({name: text})
    }
    this.setState({
      searchAdmins: admins, 
      loadingSearchAdmins: false
    })
  }, 300)

  adminsChanged(e, value) {
    let newAdmins = {}
    let adminNames = []
    let bounds = null
    for (let i = 0; i < value.length; i++) {
      const admin = value[i].data
      bounds = expandBounds(bounds, admin.bounds.coordinates[0])
      if (!newAdmins[admin.level]) newAdmins[admin.level] = []
      newAdmins[admin.level].push(admin[`${admin.level}_id`])
      adminNames.push(admin[`${admin.level}`])
    }
    this.setState({
      adminIDs: newAdmins,
      adminNames: adminNames,
      adminBounds: bounds
    })
  }

  yearChanged(e) {
    const value = e.target.value
    if (value <= this.state.maxYear && value >= this.state.minYear) {
      this.setState({
        year: value,
        yearTreatment: this.state.lockYears ? value : this.state.yearTreatment
      })
    }
  }
  yearPlus(e) {
    const year = this.state.year + 1
    this.setState({
      year: year,
      yearTreatment: this.state.lockYears ? year : this.state.yearTreatment
    })
  }
  yearMinus(e) {
    const year = this.state.year - 1
    this.setState({
      year: year,
      yearTreatment: this.state.lockYears ? year : this.state.yearTreatment
    })
  }

  yearTreatmentChanged(e) {
    const value = e.target.value
    if (value <= this.state.maxYear && value >= this.state.minYear) {
      this.setState({yearTreatment: value})
    }
  }
  yearTreatmentPlus(e) {
    this.setState({yearTreatment: this.state.yearTreatment + 1})
  }
  yearTreatmentMinus(e) {
    this.setState({yearTreatment: this.state.yearTreatment - 1})
  }

  lockYearsChanged(e) {
    const lockYears = e.target.checked
    this.setState({
      lockYears: lockYears,
      yearTreatment: lockYears ? this.state.year : this.state.yearTreatment
    })
  }

  toggleChanged(e) {
    this.setState({layers: {
      ...this.state.layers, 
      [e.target.name]: e.target.checked
    }})
  }

  openDisclaimers(e) {
    this.setState({disclaimers:true, disclaimersAnchor: e.currentTarget})
  }
  closeDisclaimers() {
    this.setState({disclaimers: false, disclaimersAnchor: null})
  }

  downloadGuide(lang) {
    window.open(`/user-guide-${lang}.pdf`, 'Download')
  }


  render() {
    return (
      <div className="search-panel">
        <IconButton color="default" size="small" 
          style={{float: 'right', marginTop: '-6px'}}
          aria-label={l.t('disclaimers')}
          title={l.t('show_disclaimers')}
          onClick={this.openDisclaimers}
        >
          <Info fontSize="large" />
        </IconButton>
        <IconButton color="default" size="small" 
          style={{float: 'right', marginTop: '-6px'}}
          aria-label={l.t('reset_map_filters')}
          title={l.t('reset_map_filters')}
          onClick={this.props.resetMap}
        >
          <Autorenew fontSize="large" />
        </IconButton>
        <InfoPopover open={this.state.disclaimers} 
          anchorEl={this.state.disclaimersAnchor} 
          handleClose={this.closeDisclaimers}
          layers={this.state.layers}
        />
        <h3>{l.t('map_options')}</h3>
        <Autocomplete
          size="small" 
          multiple
          onChange={this.adminsChanged}
          groupBy={(admin) => admin.country()}
          options={this.state.searchAdmins}
          loading={this.state.loadingSearchAdmins}
          getOptionLabel={(admin) => admin.selection()}
          style={{ width: '100%', marginBottom: '10px' }}
          filterOptions={x => x}
          renderInput={(params) => 
            <TextField {...params} 
              label={l.t('search_locations')}
              variant="outlined" 
              onChange={e => this.adminSearchChanged(e.target.value)}
            />
          }
        />
        <SearchToggle checked={this.state.layers.admin1} 
          onChange={this.toggleChanged}
          label={l.t('show_admin1_boundaries')} name="admin1"
        />
        <SearchToggle checked={this.state.layers.admin1_names} 
          onChange={this.toggleChanged}
          label={l.t('show_admin1_names')} name="admin1_names"
        />
        <SearchToggle checked={this.state.layers.ius} 
          onChange={this.toggleChanged}
          label={l.t('show_all_iu_boundaries')} name="ius"
        />

        <div className="main-layers">
          <h4 style={{margin: '15px 0 10px'}}>{l.t('layers_by_year')}</h4>
          <SearchToggle checked={this.state.layers.prevalence}
            style={{width:'130px'}}
            onChange={this.toggleChanged}
            label={l.t('tf_prevalence')} name="prevalence"
          />
          <IconButton color="primary" size="small" aria-label={l.t('previous_year')}
            disabled={this.state.year <= this.state.minYear}
            onClick={this.yearMinus}
          >
            <SkipPrevious fontSize="large" />
          </IconButton>
          <TextField label={l.t('year')}
            variant="outlined" 
            size="small" 
            style={{maxWidth: '65px'}}
            value={this.state.year}
            onChange={this.yearChanged}
          />
          <IconButton color="primary" size="small" aria-label={l.t('next_year')}
            disabled={this.state.year >= this.state.maxYear}
            onClick={this.yearPlus}
          >
            <SkipNext fontSize="large" />
          </IconButton>
        </div>

        <Divider className="divider" />

        <div className="main-layers" style={{position: 'relative'}}>
          <Checkbox
            style={{position: 'absolute', right: '-1px', top: '-36px'}}
            icon={<LockOpen />} 
            checkedIcon={<Lock color="primary" />} 
            checked={this.state.lockYears}
            onChange={this.lockYearsChanged}
            name="lockYears" 
          />
          <SearchToggle checked={this.state.layers.treatment} 
            style={{width:'130px'}}
            onChange={this.toggleChanged}
            label={l.t('treatment')} name="treatment"
          />
          <IconButton color="primary" size="small" 
            aria-label={l.t('previous_year')}
            onClick={this.yearTreatmentMinus}
            disabled={this.state.lockYears || this.state.yearTreatment <= this.state.minYear}
          >
            <SkipPrevious fontSize="large" />
          </IconButton>
          <TextField label={l.t('year')}
            variant="outlined" 
            size="small" 
            style={{maxWidth: '65px'}}
            value={this.state.yearTreatment}
            onChange={this.yearTreatmentChanged}
            disabled={this.state.lockYears}
          />
          <IconButton color="primary" size="small" 
            aria-label={l.t('nexy_year')}
            onClick={this.yearTreatmentPlus}
            disabled={this.state.lockYears || this.state.yearTreatment >= this.state.maxYear}
          >
            <SkipNext fontSize="large" />
          </IconButton>
        </div>

        <Divider className="divider" />

        <div className="summaries-layers">
          <h4 style={{margin: '15px 0 10px'}}>{l.t('summary_layers')}</h4>
          <SearchToggle checked={this.state.layers.baselineTFPrevalence} 
            onChange={this.toggleChanged}
            label={l.t('baseline_tf_prevalence')} name="baselineTFPrevalence"
          />
          <SearchToggle checked={this.state.layers.treatmentCumulative} 
            onChange={this.toggleChanged}
            label={l.t('cumulative_rounds')} name="treatmentCumulative"
          />
          <SearchToggle checked={this.state.layers.reduction50} 
            onChange={this.toggleChanged}
            label={l.t('fifty_reduction_tf_prevalence')} name="reduction50"
          />
          <SearchToggle checked={this.state.layers.endemicStatusCountry} 
            onChange={this.toggleChanged}
            label={l.t('country_elimination_status')} name="endemicStatusCountry"
          />
          <SearchToggle checked={this.state.layers.prevalenceTrichiasis} 
            onChange={this.toggleChanged}
            label={l.t('tt_prevalence')} name="prevalenceTrichiasis"
          />
        </div>

        <Divider className="divider" />

        <Legend layers={this.state.layers} />

        <Divider className="divider" />

        <div className="controls">
          <Typography style={{
            margin: '-8px 8px 0 0',
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle'
          }}>User Guide</Typography> 
          <ButtonGroup variant="contained" 
              color="default" size="small" >
            <Button onClick={() => this.downloadGuide('en')}
              startIcon={<PictureAsPdf />}
            >
              EN
            </Button>
          </ButtonGroup>

        </div>

        <Divider className="divider" />

        <div className="controls last">
          <Typography style={{
            margin: '-8px 8px 0 0',
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle'
          }}>{l.t('download_as')}</Typography> 
          <ButtonGroup variant="contained" 
              color="default" size="small" >
            <Button startIcon={<Image />}
              onClick={this.props.printMap}
            >
              {l.t('image')}
            </Button>
            <Button startIcon={<Print />} 
              onClick={this.props.printPDF}
            >
              {l.t('pdf')}
            </Button>
          </ButtonGroup>
          <Button startIcon={<Help />} color="default" 
            variant="contained" size="small"
            onClick={this.props.help}
            style={{marginLeft: '10px', display: 'none'}}
          >
            {l.t('help')}
          </Button>
          <p className="note">{l.t('note')}</p>
        </div>

      </div>
    )
  }
}