import PatternImage from '../components/PatternImage'

const backgroundSize = '50%'

const legendTitles = {
  prevalence: 'legend_tf_prevalence',
  treatment: 'legend_treatement',
  baselineTFPrevalence: 'legend_baseline_tf_prevalence',
  treatmentCumulative: 'legend_cumulative_rounds_of_treatment',
  reduction50: 'legend_50_reduction_tf_prevalence',
  endemicStatusCountry: 'legend_country_elimination_status',
  prevalenceTrichiasis: 'legend_tt_prevalence',
  ius: 'legend_ius'
}

const legendStyles = {
  prevalence: [
    {text: 'legend_lt5', style: {backgroundColor: "#8CED36"}}, 
    // {text: 'Evidence (other than conventional statistical analysis of TF data) indicates that active trachoma is not a public health problem', style: {backgroundColor: "#8CED36"}}, 
    {text: 'legend_5_99', style: {backgroundColor: "#F7FF00"}}, 
    {text: 'legend_10_299', style: {backgroundColor: "#FFCC00"}}, 
    {text: 'legend_30_499', style: {backgroundColor: "#F9865F"}}, 
    {text: 'legend_gte50', style: {backgroundColor: "#F70804"}},
    {text: 'legend_suspected_endemic', style: {
      background: new PatternImage('Suspected Endemic').getURL(),
      backgroundPNG: new PatternImage('Suspected Endemic').getPNG(),
      backgroundSize
    }}
  ],
  treatment: [
    {text: 'legend_received_treatment', style: {
      background: new PatternImage('DiagonalBlack180').getURL(),
      backgroundPNG: new PatternImage('DiagonalBlack180').getPNG(),
      backgroundSize
    }}
  ],
  baselineTFPrevalence: [
    {text: 'legend_lt5', style: {backgroundColor: "#8CED36"}}, 
    // {text: 'Evidence (other than conventional statistical analysis of TF data) indicates that active trachoma is not a public health problem', style: {backgroundColor: "#8CED36"}}, 
    {text: 'legend_5_99', style: {backgroundColor: "#F7FF00"}}, 
    {text: 'legend_10_299', style: {backgroundColor: "#FFCC00"}}, 
    {text: 'legend_30_499', style: {backgroundColor: "#F9865F"}}, 
    {text: 'legend_gte50', style: {backgroundColor: "#F70804"}}
  ],
  treatmentNoPrevalence: [
    {text: 'legend_received_treatment', style: {backgroundColor: "#7B00B4"}}
  ],
  treatmentCumulative: [
    {text: 'legend_1', style: {backgroundColor: "#F9BBF9"}},
    {text: 'legend_2', style: {backgroundColor: "#DD00FF"}},
    {text: 'legend_3', style: {backgroundColor: "#7B00B4"}},
    {text: 'legend_4', style: {backgroundColor: "#3A007E"}},
    {text: 'legend_gte5', style: {backgroundColor: "#11002F"}}
  ],
  reduction50: [
    {text: 'legend_gte_50_reduction_tf_prevalence', style: {backgroundColor: "#00FFC4"}}
  ],
  endemicStatusCountry: [
    {text: 'legend_not_require_intervention_claims_eliminated', style: {backgroundColor: "#0015FF"}},
    {text: 'legend_not_require_intervention', style: {backgroundColor: "#696969"}},
    {text: 'legend_validated_eliminated', style: {backgroundColor: "#1F9C00"}},
    {text: 'legend_known_require_intervention', style: {backgroundColor: "#D6301D"}},
    {text: 'legend_may_require_intervention', style: {backgroundColor: "#FF9900"}},
  ],
  prevalenceTrichiasis: [
    {text: 'legend_gte_02', style: {
      background: new PatternImage('DiagonalBlack').getURL(),
      backgroundPNG: new PatternImage('DiagonalBlack').getPNG(),
      backgroundSize
    }}, 
  ],
  prevalenceTrichiasisNoTF: [
    {text: 'legend_lt_02', style: {backgroundColor: "#8CED36"}}, 
    // {text: 'Evidence (other than conventional statistical analysis of TT data) indicates that TT is not a public health problem', style: {backgroundColor: "#8CED36"}}, 
    {text: 'legend_gte_02', style: {backgroundColor: "#F7FF14"}}, 
    {text: 'legend_suspected_endemic', style: {
      background: new PatternImage('Suspected Endemic').getURL(),
      backgroundPNG: new PatternImage('Suspected Endemic').getPNG(),
      backgroundSize
    }}, 
  ],
  ius: []
}

const disclaimerText = {
  all: `all_disclaimer`,
  prevalence: `tt_prevalence_maps_disclaimer`,
  baselineTFPrevalence: `baseline_tf_prevalence_disclaimer`,
  prevalenceTrichiasis: `tt_prevalence_maps_adults_disclaimer`,
  treatment: `treatment_maps_disclaimer`,
  treatmentCumulative: `cumulative_rounds_maps_disclaimer`,
  reduction50: `fifty_reduction_tf_prevalence_disclaimer`,
  endemicStatusCountry: `elimination_status_map_disclaimer`,
  endemicStatusCountryHtml: "elimination_status_map_disclaimer_html"
}

export {
  legendTitles,
  legendStyles,
  disclaimerText
}
