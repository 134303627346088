const levels = [
  'admin0',
  'admin1',
  'admin2',
  'admin3'
]

export default class Admin {
  
  constructor(data) {
    this.data = data
  }

  country() {
    return this.data.admin0
  }

  selection() {
    let index = levels.indexOf(this.data.level)
    let name = this.data[levels[index]].trim()
    let subNames = []
    while (index-- > 1) {
      subNames.push(this.data[levels[index]].trim())
    } 
    return `${name} ${subNames.length > 0 ? '('+subNames.join(', ')+')' : ''}`
  }

}