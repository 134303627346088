import React from 'react'
import mapboxgl from 'mapbox-gl'
import equal from 'deep-equal'
import { assign } from 'lodash'
import PatternImage from './PatternImage'
import mergeImages from '../service/mergeImages'
import Locale from '../locales'

import 'mapbox-gl/dist/mapbox-gl.css'
import './AtlasMap.scss'

import layerStyles from './AtlasMapLayers'

const l = new Locale()

export default class AtlasMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props.mapConfig 
    }
    this.setViewport = this.setViewport.bind(this)
    this.updateMap = this.updateMap.bind(this)
    this.mapClick = this.mapClick.bind(this)
    this.featuresPopup = this.featuresPopup.bind(this)
    this.featureEvents = this.featureEvents.bind(this)
    this.setLabelVisibility = this.setLabelVisibility.bind(this)
    this.mapRef =  React.createRef()
    this.map = null
    this.popup = null
    this.hoveredFeatureId = null
    this.zoomChanged = props.zoomChanged
  }

  async mapDataUrl() {
    return await mergeImages([{
      src: this.map.getCanvas().toDataURL('image/png')
    }, {
      src: window.location + 'mapbox-logo.png',
      width: 200,
      height: 50,
      left: 10,
      bottom: 10
    }, {
      src: window.location + 'mapbox-copyright.png',
      width: 325,
      height: 23,
      right: 10,
      bottom: 10
    }])
  }

  async print() {
    const a = document.createElement("a")
    a.href = await this.mapDataUrl()
    a.download = "gat-print.png"
    a.click()
  }

  async printPDF() {
    import('../service/PdfPrinter')
      .then(async ({ PdfPrinter }) => {
        const props = this.props
        new PdfPrinter({
          map: this.map,
          image: await this.mapDataUrl(),
          ...props
        }).print()
      })
      .catch(err => {
        console.log(err)
        alert("Unable to load printer")
      });
  }

  setViewport(viewport) {
    this.setState({viewport: viewport})
  }

  async componentDidMount() {
    mapboxgl.accessToken = this.props.mapboxAccessToken
    this.map = new mapboxgl.Map({
      container: 'atlas-map',
      style: 'mapbox://styles/itidat/ckhfacqz504hl19pjzqgozit0',
      preserveDrawingBuffer: true,
      ...this.state.viewport
    })
    this.map.addControl(new mapboxgl.NavigationControl())
    this.map.addControl(new mapboxgl.ScaleControl({
      maxWidth: 150,
      unit: 'metric'
    }), 'bottom-right')
    this.map.on('load', this.updateMap)
    this.map.on('click', this.mapClick)
    let self = this
    this.map.on('zoomend', (e) => self.zoomChanged(e.target.getZoom()))
    await this.initializeMap()
  }

  appendSearchParams(url, search) {
    const adminIDs = search.adminIDs
    if (adminIDs.admin0 && adminIDs.admin0.length > 0) 
      url.searchParams.append("admin0", adminIDs.admin0)
    if (adminIDs.admin1 && adminIDs.admin1.length > 0) 
      url.searchParams.append("admin1", adminIDs.admin1)
    if (adminIDs.admin2 && adminIDs.admin2.length > 0) 
      url.searchParams.append("admin2", adminIDs.admin2)
    if (adminIDs.admin3 && adminIDs.admin3.length > 0) 
      url.searchParams.append("admin3", adminIDs.admin3)
    if (search.year) 
      url.searchParams.append("year", search.year)
    if (search.yearTreatment) 
      url.searchParams.append("yearTreatment", search.yearTreatment)
    if (this.state.zoom)
      url.searchParams.append("zoom", this.state.zoom)
    return url
  }

  appendNoYearSearchParams(url, search) {
    const adminIDs = search.adminIDs
    if (adminIDs.admin0 && adminIDs.admin0.length > 0) 
      url.searchParams.append("admin0", adminIDs.admin0)
    if (adminIDs.admin1 && adminIDs.admin1.length > 0) 
      url.searchParams.append("admin1", adminIDs.admin1)
    if (adminIDs.admin2 && adminIDs.admin2.length > 0) 
      url.searchParams.append("admin2", adminIDs.admin2)
    if (adminIDs.admin3 && adminIDs.admin3.length > 0) 
      url.searchParams.append("admin3", adminIDs.admin3)
    if (this.state.zoom)
      url.searchParams.append("zoom", this.state.zoom)
    return url
  }

  gatDataUrl() {
    const url = new URL(this.props.gatDataUrl)
    if (this.props && this.props.search) {
      this.appendSearchParams(url, this.props.search)
    }
    return `${this.props.gatDataUrl}/tiles/{z}/{x}/{y}.pbf${url.search}`
  }

  gatCountriesUrl() {
    return `${this.props.gatDataUrl}/country_tiles/{z}/{x}/{y}.pbf`
  }

  gatIusUrl() {
    const url = new URL(this.props.gatDataUrl)
    if (this.props && this.props.search) {
      this.appendNoYearSearchParams(url, this.props.search)
    }
    return `${this.props.gatDataUrl}/ius_tiles/{z}/{x}/{y}.pbf${url.search}`
  }

  gatAdmin1Url() {
    const url = new URL(this.props.gatDataUrl)
    if (this.props && this.props.search) {
      this.appendNoYearSearchParams(url, this.props.search)
    }
    return `${this.props.gatDataUrl}/admin1_tiles/{z}/{x}/{y}.pbf${url.search}`
  }

  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.search, prevProps.search)) {
      this.updateMap()
      if (!equal(this.props.search.adminBounds, prevProps.search.adminBounds)) {
        this.refitMap()
      }
    }
    if (this.popup) {
      this.popup.remove()
      this.popup = null
    }
  }

  featuresPopup(lngLat, features) {
    let props = {}
    for (let i = 0; i < features.length; i++) {
      props = assign(props, features[i].properties)
    }
    let districts = []
    if (props.admin1) districts = districts.concat(props.admin1)
    if (props.admin2) districts = districts.concat(props.admin2)
    if (props.admin3) districts = districts.concat(props.admin3)
    let description = ``
    if (props.endemicity_status) {
      description = `
        <div class="popup">
          <h3>${props.admin0}</h3>
          <p class="districts">${districts.join('<br/>')}</p>
          <h4>${l.t('popup_elimination_status')}</h4>
          <p>${props.endemicity_status}</p>
        </div>
      `
    } else if (props.year === undefined) {
      description = `
        <div class="popup">
          <h3>${props.admin0}</h3>
          <p class="districts">${districts.join('<br/>')}</p>
        </div>
      `
    } else {
      var isTTEvidence = props.tt_category_string.indexOf('Evidence') === 0;
      var isTFEvidence = props.survey_tf_category.indexOf('Evidence') === 0;
      description = `
        <div class="popup">
          <h3>${props.admin0}</h3>
          <p class="districts">${districts.join('<br/>')}</p>
          ${this.props.layers.prevalence && this.props.year ? 
            `<h4>${this.props.year} ${l.t('popup_tf_prevalence')}</h4>` : ''
          }
          ${this.props.layers.prevalence && props.survey_tf_category ?
            `<p>${l.t('popup_tf_category')}: <strong>${l.t(props.survey_tf_category, true)}</strong></p>` : ''
          }
          ${this.props.layers.prevalence && props.survey_year && !isTFEvidence ?
            `<p>${l.t('popup_year_of_survey')}: <strong>${props.survey_year}</strong></p>` : ''
          }
          ${this.props.layers.prevalence && props.survey_tf_type && !isTFEvidence ?
            `<p>${l.t('popup_type_of_survey')}: <strong>${l.t(props.survey_tf_type, true)}</strong></p>` : ''
          }
          ${this.props.layers.treatment ? 
            `<h4>${this.props.yearTreatment} ${l.t('treatment')}</h4>` : ''
          }
          ${this.props.layers.treatment && new Date().getFullYear() !== this.props.search.yearTreatment ?
            `<p>${l.t('popup_received_treatment')}: <strong>${props.zx_treatments === 1 ? l.t('yes') : l.t('no')}</strong></p>` : ''
          }
          ${this.props.layers.treatment && new Date().getFullYear() === this.props.search.yearTreatment ?
            `<p><em>${l.t('popup_not_reported_current_year')}</em></p>` : ''
          }
          ${this.props.layers.baselineTFPrevalence && props.baseline_tf_category_string ? 
            `<h4>${l.t('popup_baseline_tf_prevalence')}</h4><p>${l.t('popup_tf_category')}: <strong>${props.baseline_tf_category_string}</strong></p><p>${l.t('popup_year_of_survey')}: <strong>${props.baseline_tf_survey_year}</strong></p>` : ''
          }
          ${this.props.layers.treatmentCumulative && props.zx_rounds_cumulative ?
            `<h4>${l.t('popup_cumulative_rounds')}</h4>` : ''
          }
          ${this.props.layers.treatmentCumulative && props.zx_rounds_cumulative ?
            `<p>${l.t('popup_rounds')}: <strong>${props.zx_rounds_cumulative}</strong></p>` : ''
          }
          ${this.props.layers.prevalenceTrichiasis && props.tt_category_string ?
            `<h4>${l.t('popup_tt_prevalence')}</h4>` : ''
          }
          ${this.props.layers.prevalenceTrichiasis && props.tt_category_string ?
            `<p>${l.t('popup_current_tt_category')}: <strong>${l.t(props.tt_category_string, true)}</strong></p>` : ''
          }
          ${this.props.layers.prevalenceTrichiasis && props.tt_survey_year && !isTTEvidence ?
            `<p>${l.t('popup_tt_survey_year')}: <strong>${props.tt_survey_year}</strong></p>` : ''
          }
          ${this.props.layers.prevalenceTrichiasis && props.tt_survey_type && !isTTEvidence ?
            `<p>${l.t('popup_tt_survey_type')}: <strong>${l.t(props.tt_survey_type, true)}</strong></p>` : ''
          }
        </div>
      `.replace(/>=/g, "≥")
    }
    this.popup = new mapboxgl.Popup()
      .setLngLat(lngLat)
      .setHTML(description)
      .addTo(this.map)
  }

  featureEvents(layer, source) {
    this.map.on('mouseleave', layer, () => {
      this.map.getCanvas().style.cursor = ''
      if (this.hoveredFeatureId) {
        this.map.setFeatureState(
          { source: source, sourceLayer: source, id: this.hoveredFeatureId },
          { hover: false }
        )
      }
      this.hoveredFeatureId = null
    })
    this.map.on('mousemove', layer, (e) => {
      if (e.features.length > 0) {
        if (this.map.getCanvas().style.cursor !== 'pointer')
          this.map.getCanvas().style.cursor = 'pointer'
        if (this.hoveredFeatureId) {
          this.map.setFeatureState(
            { source: source, sourceLayer: source, id: this.hoveredFeatureId },
            { hover: false }
          )
        }
        this.hoveredFeatureId = e.features[0].id
        this.map.setFeatureState(
          { source: source, sourceLayer: source, id: this.hoveredFeatureId },
          { hover: true }
        )
      }
    })
  }

  mapClick(e) {
    const layers = []
    if (this.props.layers.prevalence) {
      layers.push('prevalence')
      layers.push('prevalenceSuspected')
    }
    if (this.props.layers.treatment) layers.push('treatment')
    if (this.props.layers.treatmentCumulative) layers.push('treatmentCumulative')
    if (this.props.layers.baselineTFPrevalence) layers.push('baselineTFPrevalence')
    if (this.props.layers.reduction50) layers.push('reduction50')
    if (this.props.layers.endemicStatusCountry) layers.push('endemicStatusCountry')
    if (this.props.layers.prevalenceTrichiasis) layers.push('prevalenceTrichiasis')
    if (this.props.layers.ius) layers.push('ius')
    const features = this.map.queryRenderedFeatures(e.point, { layers })
    // console.log(features)
    if (features.length > 0) this.featuresPopup(e.lngLat, features)
  }

  async initializeMap() {
    const types = ['default', 'Suspected Endemic', 'DiagonalBlack', 'DiagonalBlack180']
    for (let i = 0; i < types.length; i++) { 
      this.map.loadImage((new PatternImage(types[i])).getPNG(), (err, image) => {
        if (err) throw err
        this.map.addImage(types[i], image, {pixelRatio: 6})
      })
    }
  }

  // If the bounds wrap the antimeridian (sw longitude > ne longitude), change the 
  // ne bounds to wrap around the world and be positive relative to the sw longitude.
  adaptBoundsToAntimeridian(bounds) {
    let sw = bounds[0]
    let se = bounds[3]
    let ne = bounds[2]
    if (sw[0] > ne[0] && sw[0] - se[0] > 180) {
      return [sw, [360 + ne[0], ne[1]]]
    } else {
      return [sw, ne]
    }
  }

  refitMap() {
    if (this.props.search.adminBounds) {
      var boundsToFit = this.adaptBoundsToAntimeridian(this.props.search.adminBounds)
      this.map.fitBounds(
        boundsToFit, 
        { padding: {top: 50, bottom: 50, left: 300, right: 50} }
      )
    }
  }

  setLabelVisibility(visible) {
    const layers = ['country-label', 'state-label', 'settlement-major-label',
      'settlement-minor-label', 'settlement-subdivision-label', 'poi-label',
      'natural-point-label'
    ]
    if (visible) {
      for (let i = 0; i < layers.length; i++) {
        if (this.map.getLayer(layers[i]))
          this.map.setLayoutProperty(layers[i], 'visibility', 'visible')
      }
    } else {
      for (let i = 0; i < layers.length; i++) {
        if (this.map.getLayer(layers[i]))
          this.map.setLayoutProperty(layers[i], 'visibility', 'none')
      }
    }
  }

  updateMap() {

    const url = this.gatDataUrl()
    if (this.map.getSource('gat')) {
      if (this.map.getLayer('treatment')) this.map.removeLayer('treatment')
      if (this.map.getLayer('prevalence')) this.map.removeLayer('prevalence')
      if (this.map.getLayer('baselineTFPrevalence')) this.map.removeLayer('baselineTFPrevalence')
      if (this.map.getLayer('prevalenceSuspected')) this.map.removeLayer('prevalenceSuspected')
      if (this.map.getLayer('treatmentCumulative')) this.map.removeLayer('treatmentCumulative')
      if (this.map.getLayer('reduction50')) this.map.removeLayer('reduction50')
      if (this.map.getLayer('endemicStatusCountry')) this.map.removeLayer('endemicStatusCountry')
      if (this.map.getLayer('prevalenceTrichiasis')) this.map.removeLayer('prevalenceTrichiasis')
      if (this.map.getLayer('prevalenceTrichiasisNoTFSuspectedEndemic')) this.map.removeLayer('prevalenceTrichiasisNoTFSuspectedEndemic')
      if (url !== this.map.getSource('gat').tiles[0]) {
        this.map.removeSource('gat')
        this.map.addSource('gat', {
          'type': 'vector',
          'promoteId': 'geoconnect',
          'tiles': [url]
        })
      }
    } else {
        this.map.addSource('gat', {
          'type': 'vector',
          'promoteId': 'geoconnect',
          'tiles': [url]
        })
    }

    if (this.map.getSource('gatcountries')) {
      if (this.map.getLayer('endemicStatusCountry')) this.map.removeLayer('endemicStatusCountry')
      if (!this.props.layers.endemicStatusCountry) {
        this.map.removeSource('gatcountries')
      }
    } else if (this.props.layers.endemicStatusCountry) {
      this.map.addSource('gatcountries', {
        type: 'vector',
        promoteId: 'admin0id',
        tiles: [this.gatCountriesUrl()]
      })
    }

    if (this.props.layers.endemicStatusCountry) {
      this.map.addLayer({
        ...layerStyles.endemicStatusCountry,
        id: 'endemicStatusCountry'
      }, 'waterway')
      this.featureEvents('endemicStatusCountry', 'gatcountries')
    }
    if (this.props.layers.prevalence) {
      this.map.addLayer({
        ...layerStyles.prevalence,
        id: 'prevalence',
        filter: [
          'all', 
          ['==', 'year', this.props.year],
          ['!=', 'survey_tf_category', 'Suspected Endemic']
        ]
      }, 'waterway')
      this.featureEvents('prevalence', 'gat')
    }
    if (this.props.layers.prevalence) {
      this.map.addLayer({
        ...layerStyles.prevalenceSuspected,
        id: 'prevalenceSuspected',
        filter: [
          'all', 
          ['==', 'year', this.props.year],
          ['==', 'survey_tf_category', 'Suspected Endemic']
        ]
      }, 'waterway')
      this.featureEvents('prevalenceSuspected', 'gat')
    }
    if (this.props.layers.baselineTFPrevalence) {
      this.map.addLayer({
        ...layerStyles.baselineTFPrevalence,
        id: 'baselineTFPrevalence',
        filter: ['has', 'baseline_tf_category_string']
      }, 'waterway')
      this.featureEvents('baselineTFPrevalence', 'gat')
    }
    if (this.props.layers.treatment) {
      const treatmentStyle = this.props.layers.prevalence ?
        layerStyles.treatment : layerStyles.treatmentNoPrevalence
      this.map.addLayer({
        ...treatmentStyle,
        id: 'treatment',
        filter: [
          'all', 
          ['==', 'year', this.props.yearTreatment],
          ['==', 'zx_treatments', 1]
        ]
      }, 'waterway')
      this.featureEvents('treatment', 'gat')
    }
    if (this.props.layers.treatmentCumulative) {
      this.map.addLayer({
        ...layerStyles.treatmentCumulative,
        id: 'treatmentCumulative',
        filter: ['>=', 'zx_rounds_cumulative', 1]
      }, 'waterway')
      this.featureEvents('treatmentCumulative')
    }
    if (this.props.layers.reduction50) {
      this.map.addLayer({
        ...layerStyles.reduction50,
        id: 'reduction50',
        filter: ['==', 'decreased_50', 1]
      }, 'waterway')
      this.featureEvents('reduction50', 'gat')
    }
    if (this.props.layers.prevalenceTrichiasis) {
      const prevalenceTrichiasisStyle = this.props.layers.prevalence ?
        layerStyles.prevalenceTrichiasis : layerStyles.prevalenceTrichiasisNoTF
      this.map.addLayer({
        ...prevalenceTrichiasisStyle,
        id: 'prevalenceTrichiasis',
        filter: ['has', 'tt_category_string']
      }, 'waterway')
      this.featureEvents('prevalenceTrichiasis', 'gat')
    }
    if (this.props.layers.prevalenceTrichiasis && !this.props.layers.prevalence) {
      this.map.addLayer({
        ...layerStyles.prevalenceTrichiasisNoTFSuspectedEndemic,
        id: 'prevalenceTrichiasisNoTFSuspectedEndemic',
        filter: ['has', 'tt_category_string']
      }, 'waterway')
      this.featureEvents('prevalenceTrichiasisNoTFSuspectedEndemic', 'gat')
    }

    const iusUrl = this.gatIusUrl()
    if (this.map.getSource('ius') &&
      (!this.props.layers.ius || iusUrl !== this.map.getSource('ius').tiles[0])) {
      this.map.removeLayer('ius')
      this.map.removeLayer('iusBorder')
      this.map.removeSource('ius')
    }
    if (this.props.layers.ius && !this.map.getSource('ius')) {
      this.map.addSource('ius', {
        type: 'vector',
        promoteId: 'geoconnect',
        tiles: [iusUrl]
      })
      this.map.addLayer({
        id: 'iusBorder',
        ...layerStyles.iusBorder
      }, 'waterway')
      this.map.addLayer({
        id: 'ius',
        ...layerStyles.ius
      }, 'waterway')
      this.featureEvents('ius', 'ius')
    }

    const adminUrl = this.gatAdmin1Url()
    if (this.map.getSource('admin1') &&
      (!(this.props.layers.admin1 && this.props.layers.admin1_names) || adminUrl !== this.map.getSource('admin1').tiles[0])) {
        if (this.map.getLayer('admin1')) {
          this.map.removeLayer('admin1')
        }
        if (this.map.getLayer('admin1_names')){
          this.map.removeLayer('admin1_names')
          this.setLabelVisibility(true)
        }
        this.map.removeSource('admin1')
    }
    if (this.props.layers.admin1 || this.props.layers.admin1_names) {
      if (!this.map.getSource('admin1')) {
        this.map.addSource('admin1', {
          type: 'vector',
          promoteId: 'geoconnect',
          tiles: [this.gatAdmin1Url()]
        })
      } 
    }
    if (this.props.layers.admin1) {      
      if (!this.map.getLayer('admin1')) {
        this.map.addLayer({
          id: 'admin1',
          ...layerStyles.admin1
        }, 'waterway')
      }
    }
    if (this.props.layers.admin1_names) {
      if (!this.map.getLayer('admin1_names')) {
        this.setLabelVisibility(false)
        this.map.addLayer({
          id: 'admin1_names',
          ...layerStyles.admin1_names
        })
      }
    }
  }

  render() {
    return (
      <div id="atlas-map" ref={this.mapRef}></div>
    )
  }

}
