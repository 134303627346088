export default {
  yes: 'Oui',
  no: 'Non',
  map_options: "Options de carte",
  reset_map_filters: "Réinitialiser les filtres et l'emplacement de la carte",
  disclaimers: "Avertissements",
  show_disclaimers: "Afficher les avertissements",
  search_locations: "Rechercher les emplacements",
  no_options: "Aucune option",
  show_admin1_boundaries: "Afficher les limites Admin1",
  show_admin1_names: "Afficher les noms Admin1",
  show_all_iu_boundaries: "Afficher les limites de toutes les IU",
  layers_by_year: "Thèmes de carte par année",
  tf_prevalence: "Prévalence du TF",
  year: "Année",
  previous_year: "Précédente",
  next_year: "Prochaine",
  treatment: "Traitement",
  summary_layers: "Thèmes de carte sommaire",
  baseline_tf_prevalence: "Prévalence de base du TF",
  cumulative_rounds: "Rounds cumulatifs de traitement",
  fifty_reduction_tf_prevalence: "Réduction de 50 % de la prévalence du TF",
  country_elimination_status: "Situation de l'élimination par pays",
  tt_prevalence: "Prévalence du TT",
  legend: "Légende",
  download_as: "Télécharger comme",
  image: "Image",
  user_guide: "Mode d'emploi",
  pdf: "PDF",
  zoom_in: "Rapprocher",
  zoom_out: "Éloigner",
  reset_bearing_to_north: "Réinitialiser le relèvement au nord",
  note: "Remarque : l'attribution du logo et du texte Mapbox est obligatoire et ne peut être modifiée pour les cartes de l'Atlas du Trachome utilisées dans la publication.",
  all_disclaimer: "Ces cartes ont été conçues par l’Initiative internationale contre le trachome (ITI) et développées par Bastion Data. Les données présentées sur les cartes ont été rapportées par les responsables de programme à la base de données GET2020. Les limites et les noms indiqués et les désignations utilisées sur cette carte n'impliquent pas l'expression d'une quelconque opinion de la part des développeurs, ou des institutions auxquelles ils sont affiliés, concernant le statut légal d'un pays, d'un territoire, d'une ville ou d'une zone ou de ses autorités, ou concernant la délimitation de ses frontières ou limites.",
  tt_prevalence_maps_disclaimer: "Les cartes de prévalence du TF présentent la catégorie de prévalence la plus récente du TF chez les enfants âgés de 1 à 9 ans par district pour l'année choisie.",
  baseline_tf_prevalence_disclaimer: "Les cartes de prévalence de base du TF présentent la catégorie de prévalence de base du TF chez les enfants âgés de 1 à 9 ans par district.",
  tt_prevalence_maps_adults_disclaimer: "Les cartes de prévalence du TT présentent la catégorie de prévalence la plus récente du TT chez les adultes âgés de 15 ans et plus par district.",
  treatment_maps_disclaimer: "Les cartes de traitement indiquent si un district a reçu un traitement pour l'année choisie.",
  cumulative_rounds_maps_disclaimer: "La carte des rounds cumulatifs présente le nombre cumulatif de rounds de traitement distribués par district.",
  fifty_reduction_tf_prevalence_disclaimer: "La carte de réduction de 50 % présente les districts dans lesquels il y a une réduction ≥ 50 % de la prévalence du TF chez les enfants âgés de 1 à 9 ans entre l'enquête de base et l'enquête la plus récente.",
  elimination_status_map_disclaimer: "La carte de situation de l'élimination par pays présente la situation de l'élimination du trachome en tant que problème de santé publique par pays. Les informations sont basées sur la situation la plus récente rapportée dans L’Observatoire mondial de la Santé (http://apps.who.int/gho/data/node.main.A1645T?lang=en).",
  legend_tf_prevalence: "Prévalence du TF",
  legend_lt5: "<5%",
  legend_5_99: "5-9.9%",
  legend_10_299: "10-29.9%",
  legend_30_499: "30-49.9%",
  legend_gte50: "≥50%",
  legend_1: "1",
  legend_2: "2",
  legend_3: "3",
  legend_4: "4",
  legend_gte5: "≥5",
  legend_lt_02: "<0.2%",
  legend_gte_02: "≥0.2%",
  legend_suspected_endemic: "Endémie suspectée",
  legend_treatement: "Traitement",
  legend_received_treatment: "Recevoir un traitement",
  legend_baseline_tf_prevalence: "Prévalence de base du TF",
  legend_cumulative_rounds_of_treatment: "Rounds cumulatifs de traitement",
  legend_50_reduction_tf_prevalence: "Réduction de 50 % de la prévalence du TF",
  legend_gte_50_reduction_tf_prevalence: "≥ 50% réduction de la prévalence du TF",
  legend_country_elimination_status: "Situation de l'élimination par pays",
  legend_not_require_intervention_claims_eliminated: "On pense qu’il n’est pas nécessaire d’intervenir, l’élimination est revendiquée",
  legend_not_require_intervention: "On pense qu’il n’est pas nécessaire d’intervenir",
  legend_validated_eliminated: "Validation de l’élimination",
  legend_known_require_intervention: "On sait que des interventions sont nécessaires",
  legend_may_require_intervention: "Des interventions pourraient s’avérer nécessaires, intervention nécessaire",
  legend_tt_prevalence: "Prévalence du TT",
  legend_ius: "IUs",
  popup_elimination_status: "Situation de l'élimination",
  popup_tf_prevalence: "Prévalence du TF",
  popup_tf_category: "Catégorie TF",
  popup_year_of_survey: "Année de l'enquête",
  popup_type_of_survey: "Type d'enquête",
  popup_treatment: "Traitement",
  popup_received_treatment: "Recevoir un traitement",
  popup_baseline_tf_prevalence: "Prévalence de base du TF",
  popup_cumulative_rounds: "Rounds cumulatifs de traitement",
  popup_rounds: "Rounds de traitement",
  popup_tt_prevalence: "Prévalence du TT",
  popup_current_tt_category: "Catégorie TT actuelle",
  popup_tt_survey_year: "Année de l'enquête TT",
  popup_tt_survey_type: "Type d'enquête TT",
  popup_not_reported_current_year: "Les données de traitement de l'année en cours ne sont déclarées que l'année suivante.",
  "Suspected Endemic": "Endémie suspectée",
  "5-9.9%": "5-9.9%",
  "10-29.9%": "10-29.9%",
  "30-49.9%": "30-49.9%",
  "<5%": "<5%",
  ">=50%": ">=50%",
  "OR": "OR",
  "Impact": "Impact",
  "Surveillance": "Surveillance",
  "Baseline": "Prévalence de base",
  "TT1": "TT1",
  ">=0.2%": ">=0.2%",
  "<0.2%": "<0.2%"
}
