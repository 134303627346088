import React from 'react'
import { Popover, Typography } from '@material-ui/core'
import { disclaimerText } from '../service/Definitions'
import Locale from '../locales'

const pStyle = {fontSize: '12px'};
const l = new Locale();

export default class InfoPopover extends React.Component {

  render() {
    return (
      <Popover
        id="info-popover"
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        onClose={this.props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography component="div" style={{margin: '1rem', maxWidth: '500px'}}>
          <h3>Disclaimers</h3>
          <p style={pStyle}>
            { l.t(disclaimerText.all) }
          </p>
          {this.props.layers.prevalence && 
            <p style={pStyle}>
              { l.t(disclaimerText.prevalence) }
            </p>
          }
          {this.props.layers.baselineTFPrevalence && 
            <p style={pStyle}>
              { l.t(disclaimerText.baselineTFPrevalence) }
            </p>
          }
          {this.props.layers.prevalenceTrichiasis && 
            <p style={pStyle}>
              { l.t(disclaimerText.prevalenceTrichiasis) }
            </p>
          }
          {this.props.layers.treatment && 
            <p style={pStyle}>
              { l.t(disclaimerText.treatment) }
            </p>
          }
          {this.props.layers.treatmentCumulative && 
            <p style={pStyle}>
              { l.t(disclaimerText.treatmentCumulative) }
            </p>
          }
          {this.props.layers.reduction50 && 
            <p style={pStyle}>
              { l.t(disclaimerText.reduction50) }
            </p>
          }
          {this.props.layers.endemicStatusCountry && 
            <p style={pStyle}>
              { l.t(disclaimerText.endemicStatusCountryHtml) }
            </p>
          }
        </Typography>
      </Popover>
    )
  }

}
