import en from './en'
import fr from './fr'

export default class Locale {

  constructor() {
    this.locale = navigator.language.startsWith('fr') ? 'fr' : 'en'
  }

  t(key, failover) {
    var text = failover ? key : 'TRANSLATION MISSING';
    if (this.locale === 'fr') {
      if (fr[key]) text = fr[key];
    } else {
      if (en[key]) text = en[key];
    }
    return text;
  }
}