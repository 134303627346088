export default {
  yes: 'Yes',
  no: 'No',
  map_options: "Map Options",
  reset_map_filters: "Reset map filters and location",
  disclaimers: "Disclaimers",
  show_disclaimers: "Show disclaimers",
  search_locations: "Search locations",
  no_options: "No options",
  show_admin1_boundaries: "Show Admin1 Boundaries ",
  show_admin1_names: "Show Admin1 Names",
  show_all_iu_boundaries: "Show All IU Boundaries",
  layers_by_year: "Layers by Year",
  tf_prevalence: "TF Prevalence",
  year: "Year",
  previous_year: "Previous",
  next_year: "Next",
  treatment: "Treatment",
  summary_layers: "Summary Layers",
  baseline_tf_prevalence: "Baseline TF Prevalence",
  cumulative_rounds: "Cumulative Rounds of Treatment",
  fifty_reduction_tf_prevalence: "50% Reduction in TF Prevalence",
  country_elimination_status: "Country Elimination Status",
  tt_prevalence: "TT Prevalence",
  legend: "Legend",
  user_guide: "User Guide",
  download_as: "Download as",
  image: "Image",
  pdf: "PDF",
  zoom_in: "Zoom in",
  zoom_out: "Zoom out ",
  reset_bearing_to_north: "Reset bearing to north",
  note: "Note: the Mapbox logo and text attribution is required and cannot be modified for Trachoma Atlas maps used in publications",
  all_disclaimer: "These maps were designed by the International Trachoma Initiative (ITI) and developed by Bastion Data. The data presented on the maps were reported by programme managers to the joint ITI/WHO GET2020 database. The boundaries and names shown and the designations used on this map do not imply the expression of any opinion whatsoever on the part of the developers, or the institutions with which they are affiliated, concerning the legal status of any country, territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.",
  tt_prevalence_maps_disclaimer: "TF prevalence maps present the most recent categorical prevalence of TF in children aged 1-9 years by district for the selected year.",
  baseline_tf_prevalence_disclaimer: "Baseline TF prevalence maps present the baseline categorical prevalence of TF in children aged 1-9 years by district.",
  tt_prevalence_maps_adults_disclaimer: "TT prevalence maps present the most recent categorical prevalence of TT in adults aged 15+ years by district.",
  treatment_maps_disclaimer: "Treatment maps indicate whether a district received treatment for the selected year.",
  cumulative_rounds_maps_disclaimer: "The cumulative rounds map presents the cumulative number of rounds of treatment ever distributed by district.",
  fifty_reduction_tf_prevalence_disclaimer: "The 50% reduction map presents districts with ≥ 50% reduction in prevalence of TF in children aged 1-9 years from the baseline survey to the most recent survey.",
  elimination_status_map_disclaimer: "The country elimination status map presents the status of elimination of trachoma as a public health problem by country. Information is based on the most recent status reported in the Global Health Observatory (http://apps.who.int/gho/data/node.main.A1645T?lang=en)",
  elimination_status_map_disclaimer_html: "The country elimination status map presents the status of elimination of trachoma as a public health problem by country. Information is based on the most recent status reported in the Global Health Observatory (http://apps.who.int/gho/data/node.main.A1645T?lang=en)",
  legend_tf_prevalence: "TF Prevalence",
  legend_lt5: "<5%",
  legend_5_99: "5-9.9%",
  legend_10_299: "10-29.9%",
  legend_30_499: "30-49.9%",
  legend_gte50: "≥50%",
  legend_1: "1",
  legend_2: "2",
  legend_3: "3",
  legend_4: "4",
  legend_gte5: "≥5",
  legend_lt_02: "<0.2%",
  legend_gte_02: "≥0.2%",
  legend_suspected_endemic: "Suspected Endemic",
  legend_treatement: "Treatment",
  legend_received_treatment: "Received treatment",
  legend_baseline_tf_prevalence: "Baseline TF Prevalence",
  legend_cumulative_rounds_of_treatment: "Cumulative Rounds of Treatment",
  legend_50_reduction_tf_prevalence: "50% Reduction in TF Prevalence",
  legend_gte_50_reduction_tf_prevalence: "≥ 50% reduction in TF prevalence",
  legend_country_elimination_status: "Country Elimination Status",
  legend_not_require_intervention_claims_eliminated: "Thought to not require interventions; claims to have eliminated",
  legend_not_require_intervention: "Thought to not require interventions",
  legend_validated_eliminated: "Validated as having eliminated",
  legend_known_require_intervention: "Known to require interventions",
  legend_may_require_intervention: "May require interventions; investigation needed",
  legend_tt_prevalence: "TT Prevalence",
  legend_ius: "IUs",
  popup_elimination_status: "Elimination status",
  popup_tf_prevalence: "TF Prevalence",
  popup_tf_category: "TF Category",
  popup_year_of_survey: "Year of Survey",
  popup_type_of_survey: "Type of Survey",
  popup_treatment: "Treatment",
  popup_received_treatment: "Received treatment",
  popup_baseline_tf_prevalence: "Baseline TF Prevalence",
  popup_cumulative_rounds: "Cumulative Rounds of Treatment",
  popup_rounds: "Rounds of Treatment",
  popup_tt_prevalence: "TT Prevalence",
  popup_current_tt_category: "Current TT Category",
  popup_tt_survey_year: "TT Survey Year",
  popup_tt_survey_type: "TT Survey Type",
  popup_not_reported_current_year: "Treatment data for the current year are not reported until the following year.",
  "Suspected Endemic": "Suspected Endemic",
  "5-9.9%": "5-9.9%",
  "10-29.9%": "10-29.9%",
  "30-49.9%": "30-49.9%",
  "<5%": "<5%",
  ">=50%": ">=50%",
  "OR": "OR",
  "Impact": "Impact",
  "Surveillance": "Surveillance",
  "Baseline": "Baseline",
  "TT1": "TT1",
  ">=0.2%": ">=0.2%",
  "<0.2%": "<0.2%"
}
