import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'


export default class SearchToggle extends React.Component {

  render() {
    return (
      <FormControlLabel
        style={{display: 'block'}}
        control={
          <Checkbox
            style={{padding: '0 4px 0 8px'}}
            color="primary"
          />
        }
        label={this.props.label}
        {...this.props}
      />
    )
  }

}