import axios from 'axios'

import Admin from './Admin'

export default class GatDataService {

  constructor(props) {
    this.url = props.url
  }

  async getAdmins(params) {
    const response = await axios.get(`${this.url}/admin`, { params })
    return response.data.map(admin => new Admin(admin))
  }

}